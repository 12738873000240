import axios from "axios";
var SERVICES_URL = process.env.SERVICES_URL;
var API_URL = "".concat(SERVICES_URL, "/routes");
export var setCameraAngle = function (src, dst, cut) {
    return axios
        .put("".concat(API_URL, "/src/").concat(src, "/dst/").concat(dst, "/").concat(cut))
        .then(function (response) { return response.data; });
};
export var setAutomation = function (enabled) {
    return axios
        .put("".concat(API_URL, "/setEnableDisable/").concat(enabled))
        .then(function (response) { return response.data; });
};
export var getAutomation = function () {
    return axios.get("".concat(API_URL, "/getAutomationState")).then(function (response) { return response.data; });
};
export var getLastAction = function () {
    return axios.get("".concat(API_URL, "/getLastAction")).then(function (response) { return response.data; });
};
