import axios from "axios";
var SERVICES_URL = process.env.SERVICES_URL;
var API_URL = "".concat(SERVICES_URL, "/statsapi");
export var getStatsGameData = function (gamePk) {
    //axios.get(`${API_URL}/v1.1/game/${gamePk}/feed/live`).then(response => response.data);
    return axios.get("".concat(API_URL, "/gameData/").concat(gamePk)).then(function (response) { return response.data; });
};
export var getSchedule = function (date) {
    //    axios.get(`${API_URL}/v1/schedule/?sportId=1&date=${date}`).then(response => response.data);
    return axios.get("".concat(API_URL, "/schedule/today")).then(function (response) { return response.data; });
};
// axios.get(`${API_URL}/schedule/2024-04-21`).then((response) => response.data);
