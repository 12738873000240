var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { FaTimesCircle } from 'react-icons/fa';
export var SidebarContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    position: fixed;\n    z-index: 999;\n    width: 100%;\n    height: 100%;\n    background: linear-gradient(to left, #090d12, #032e6e);         \n    display: grid;\n    align-items: center;\n    top: 0;\n    left: 0;\n    transition: 0.3s ease-in-out;\n    opacity: ", ";\n    top: ", ";\n    z-index: 999;\n    // left: ", ";\n    overflow-y: auto;\n"], ["\n    position: fixed;\n    z-index: 999;\n    width: 100%;\n    height: 100%;\n    background: linear-gradient(to left, #090d12, #032e6e);         \n    display: grid;\n    align-items: center;\n    top: 0;\n    left: 0;\n    transition: 0.3s ease-in-out;\n    opacity: ", ";\n    top: ", ";\n    z-index: 999;\n    // left: ", ";\n    overflow-y: auto;\n"])), function (_a) {
    var isOpen = _a.isOpen;
    return (isOpen ? '100%' : '0');
}, function (_a) {
    var isOpen = _a.isOpen;
    return (isOpen ? '0' : '-100%');
}, function (_a) {
    var isOpen = _a.isOpen;
    return (isOpen ? '0' : '-100%');
});
export var CloseIcon = styled(FaTimesCircle)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    color: #fff;\n    font-size: 2rem;\n    display: flex;\n    margin-top: 10px;\n    margin-right: 220px;\n"], ["\n    color: #fff;\n    font-size: 2rem;\n    display: flex;\n    margin-top: 10px;\n    margin-right: 220px;\n"])));
export var Icon = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    position: absolute;\n    top: 0.7rem;\n    right: 2.5rem;\n    background: transparent;\n    border: transparent;\n    font-size: 2rem;\n    cursor: pointer;\n    outline: none;\n    margin-right: -150px;\n    margin-top: 12px;\n\n    @media screen and (max-width: 1380px) {\n        margin-right: -230px;\n    }\n"], ["\n    position: absolute;\n    top: 0.7rem;\n    right: 2.5rem;\n    background: transparent;\n    border: transparent;\n    font-size: 2rem;\n    cursor: pointer;\n    outline: none;\n    margin-right: -150px;\n    margin-top: 12px;\n\n    @media screen and (max-width: 1380px) {\n        margin-right: -230px;\n    }\n"])));
export var Icon2 = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    position: absolute;\n    top: 0.7rem;\n    right: 2.5rem;\n    background: transparent;\n    border: transparent;\n    font-size: 2.2rem;\n    cursor: pointer;\n    outline: none;\n    margin-right: 120px;\n    margin-top: 20px;\n\n    @media screen and (max-width: 1380px) {\n        margin-right: 40px;\n    }\n"], ["\n    position: absolute;\n    top: 0.7rem;\n    right: 2.5rem;\n    background: transparent;\n    border: transparent;\n    font-size: 2.2rem;\n    cursor: pointer;\n    outline: none;\n    margin-right: 120px;\n    margin-top: 20px;\n\n    @media screen and (max-width: 1380px) {\n        margin-right: 40px;\n    }\n"])));
export var TopLine = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    color: #ffff;\n    font-size: 1.5rem;\n    line-height: 16px;\n    font-weight: 700;\n    letter-spacing: 1.4px;\n    text-transform: uppercase;\n    margin-bottom: 16px;\n    align-items: center;\n    display: grid;\n    margin-top: s0px;\n    text-align: center;\n"], ["\n    color: #ffff;\n    font-size: 1.5rem;\n    line-height: 16px;\n    font-weight: 700;\n    letter-spacing: 1.4px;\n    text-transform: uppercase;\n    margin-bottom: 16px;\n    align-items: center;\n    display: grid;\n    margin-top: s0px;\n    text-align: center;\n"])));
export var NavLogo = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    // position: fixed;\n    top: 0;\n    left: 50%;\n    transform: translateX(-50%);\n    z-index: 1000; \n    padding: 10px; \n    text-align: center; \n    width: 100%;\n    font-size: 3.6rem !important;\n    margin-left: 300px;\n    font-weight: bold;\n    text-transform: uppercase;\n    margin-top: 10px;\n\n    background-image: linear-gradient(\n      -225deg,\n      #121212 10%,\n      #ffff 29%,\n      #BF0C3D 67%,\n      #051E41 100%\n    );\n    background-size: auto auto;\n    background-clip: border-box;\n    background-size: 200% auto;\n    background-clip: text;\n    text-fill-color: transparent;\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n    animation: textclip 8s linear infinite;\n    display: inline-block;\n        font-size: 190px;\n  }\n  \n  @keyframes textclip {\n    to {\n      background-position: 200% center;\n    }\n  }\n\n  \n  \n"], ["\n    // position: fixed;\n    top: 0;\n    left: 50%;\n    transform: translateX(-50%);\n    z-index: 1000; \n    padding: 10px; \n    text-align: center; \n    width: 100%;\n    font-size: 3.6rem !important;\n    margin-left: 300px;\n    font-weight: bold;\n    text-transform: uppercase;\n    margin-top: 10px;\n\n    background-image: linear-gradient(\n      -225deg,\n      #121212 10%,\n      #ffff 29%,\n      #BF0C3D 67%,\n      #051E41 100%\n    );\n    background-size: auto auto;\n    background-clip: border-box;\n    background-size: 200% auto;\n    background-clip: text;\n    text-fill-color: transparent;\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n    animation: textclip 8s linear infinite;\n    display: inline-block;\n        font-size: 190px;\n  }\n  \n  @keyframes textclip {\n    to {\n      background-position: 200% center;\n    }\n  }\n\n  \n  \n"])));
export var Alert2 = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    position: absolute;\n    top: 0.7rem;\n    right: 2.5rem;\n    background: transparent;\n    border: transparent;\n    font-size: 2.2rem;\n    cursor: pointer;\n    outline: none;\n    margin-right: 200px;\n    margin-top: 20px;\n\n    @media screen and (max-width: 1380px) {\n        margin-right: 120px;\n    }\n\n"], ["\n    position: absolute;\n    top: 0.7rem;\n    right: 2.5rem;\n    background: transparent;\n    border: transparent;\n    font-size: 2.2rem;\n    cursor: pointer;\n    outline: none;\n    margin-right: 200px;\n    margin-top: 20px;\n\n    @media screen and (max-width: 1380px) {\n        margin-right: 120px;\n    }\n\n"])));
export var Nav = styled.nav(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    height: 80px;\n    margin-top: -80px;\n    margin-bottom: 200px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 1rem;\n    position: sticky;\n    top: 0;\n    z-index: 10;\n\n    // @media screen and (max-width: 1380px) {\n    //     // width: %;\n    //     margin-left: 70px;\n\n    // }\n\n"], ["\n    height: 80px;\n    margin-top: -80px;\n    margin-bottom: 200px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 1rem;\n    position: sticky;\n    top: 0;\n    z-index: 10;\n\n    // @media screen and (max-width: 1380px) {\n    //     // width: %;\n    //     margin-left: 70px;\n\n    // }\n\n"])));
export var IconsContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    \n\n    \n"], ["\n    display: flex;\n    align-items: center;\n    \n\n    \n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
