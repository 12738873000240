var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
var TableContainerWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-left: 150px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 70%;\n  // min-height: 70vh; \n  // min-width: 90vh;\n  box-sizing: border-box;\n  margin-bottom: 10px;\n  margin-top: 40px;\n\n  @media screen and (max-width: 1380px) {\n    margin-top: 50px;\n  }\n\n  @media screen and (max-width: 1050px) {\n    margin-top: 90px;\n  }\n"], ["\n  margin-left: 150px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 70%;\n  // min-height: 70vh; \n  // min-width: 90vh;\n  box-sizing: border-box;\n  margin-bottom: 10px;\n  margin-top: 40px;\n\n  @media screen and (max-width: 1380px) {\n    margin-top: 50px;\n  }\n\n  @media screen and (max-width: 1050px) {\n    margin-top: 90px;\n  }\n"])));
var StyledTable = styled.table(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  border-spacing: 0;\n  border-radius: 10px;\n  overflow: hidden;\n  background-color: #121212;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  color: #ffffff;\n"], ["\n  width: 100%;\n  border-spacing: 0;\n  border-radius: 10px;\n  overflow: hidden;\n  background-color: #121212;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  color: #ffffff;\n"])));
var StyledTableHead = styled.thead(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: #bf0c3d;\n  color: white;\n"], ["\n  background: #bf0c3d;\n  color: white;\n"])));
var StyledTableRow = styled.tr(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: none;\n  &:nth-child(even) \n  {\n    background-color: #1D1D1F; \n  }\n"], ["\n  border: none;\n  &:nth-child(even) \n  {\n    background-color: #1D1D1F; \n  }\n"])));
var StyledTableCell = styled.td(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 12px 12px 12px 12px;\n  font-size: 16px;\n  text-align: ", ";\n  border: none;\n"], ["\n  padding: 12px 12px 12px 12px;\n  font-size: 16px;\n  text-align: ", ";\n  border: none;\n"])), function (props) { return props.align || "left"; });
var StyledTableHeader = styled.th(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  padding: 12px;\n  font-size: 24px;\n  text-align: center;\n"], ["\n  padding: 12px;\n  font-size: 24px;\n  text-align: center;\n"])));
var GamesTable = function (_a) {
    var displayDate = _a.displayDate, games = _a.games;
    return (_jsx(TableContainerWrapper, { children: _jsxs(StyledTable, { children: [_jsx(StyledTableHead, { children: _jsx(StyledTableHeader, __assign({ colSpan: 5 }, { children: displayDate })) }), _jsx("tbody", { children: games.map(function (game, index) { return (_jsxs(StyledTableRow, __assign({ style: {
                            backgroundColor: game.gameState === "Manager challenge" ||
                                game.gameState === "Umpire review"
                                ? "#bf0c3d"
                                : undefined,
                        } }, { children: [_jsx(StyledTableCell, __assign({ align: "center" }, { children: game.replayGamePk })), _jsx(StyledTableCell, __assign({ align: "center" }, { children: game.gameString })), _jsx(StyledTableCell, __assign({ align: "center" }, { children: game.gameTime })), _jsx(StyledTableCell, __assign({ align: "center" }, { children: game.gameState })), _jsx(StyledTableCell, __assign({ align: "center" }, { children: game.trucks }))] }), game.gamePk)); }) })] }) }));
};
export default GamesTable;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
