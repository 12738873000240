var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
// import { game } from "../../Types/StatsApiTypes";
// import { useReview } from "../../contexts/ReviewDataContext";
import SettingsLayout from "../../modules/SettingsLayout";
import { ReviewProvider } from "../../contexts/ReviewDataContext";
import { BiSolidCircle } from "react-icons/bi";
import { FaRunning } from "react-icons/fa";
import { useAutomation } from "../../contexts/AutomationDataContext";
import { useReview } from "../../contexts/ReviewDataContext";
import styled, { ThemeProvider, keyframes, css } from "styled-components";
import { SidebarContainer, Icon, CloseIcon, Icon2, NavLogo, Alert2, Nav, IconsContainer, } from "./SettingsElements";
var flashAnimation = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% { color: red; }\n  50% { color: grey; }\n  100% { color: red; }\n"], ["\n  0% { color: red; }\n  50% { color: grey; }\n  100% { color: red; }\n"])));
var flashStyles = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  animation: ", " 1s infinite;\n"], ["\n  animation: ", " 1s infinite;\n"])), flashAnimation);
var APPReplayIcon = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: -260px;\n  color: ", ";\n  ", ";\n"], ["\n  margin-right: -260px;\n  color: ", ";\n  ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.rocRunnerIconColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.rocRunnerReviewStatus && flashStyles;
});
var scrollAnimation = keyframes(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  0% {\n    transform: translateX(50%);\n  }\n  100% {\n    transform: translateX(60%);\n  }\n"], ["\n  0% {\n    transform: translateX(50%);\n  }\n  100% {\n    transform: translateX(60%);\n  }\n"])));
var ScrollingBanner = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  background: transparent;\n  border: transparent;\n  font-size: 0.9rem;\n  // margin-right: 230px;\n  margin-top: 10px;\n  margin-right: -600px;\n  overflow: hidden;\n  animation: ", " 10s linear infinite;\n  color: ", ";\n  font-weight: bold;\n  margin-right: ", ";\n  font-size: ", ";\n"], ["\n  position: absolute;\n  background: transparent;\n  border: transparent;\n  font-size: 0.9rem;\n  // margin-right: 230px;\n  margin-top: 10px;\n  margin-right: -600px;\n  overflow: hidden;\n  animation: ", " 10s linear infinite;\n  color: ", ";\n  font-weight: bold;\n  margin-right: ", ";\n  font-size: ", ";\n"])), scrollAnimation, function (_a) {
    var statsApiReviewStatus = _a.statsApiReviewStatus, rocRunnerReviewStatus = _a.rocRunnerReviewStatus;
    return statsApiReviewStatus || rocRunnerReviewStatus ? "red" : "grey";
}, function (_a) {
    var statsApiReviewStatus = _a.statsApiReviewStatus, rocRunnerReviewStatus = _a.rocRunnerReviewStatus;
    return statsApiReviewStatus || rocRunnerReviewStatus ? "-100px" : "-160px";
}, function (_a) {
    var statsApiReviewStatus = _a.statsApiReviewStatus, rocRunnerReviewStatus = _a.rocRunnerReviewStatus;
    return statsApiReviewStatus || rocRunnerReviewStatus ? "1.2rem" : "1rem";
});
var ColoredCircleWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (_a) {
    var enabled = _a.enabled;
    return (enabled ? "#09DC2A" : "white");
});
var ColoredCircle = function (_a) {
    var enabled = _a.enabled;
    return (_jsx(ColoredCircleWrapper, __assign({ enabled: enabled }, { children: _jsx(BiSolidCircle, {}) })));
};
var Sidebar = function (_a) {
    var isOpen = _a.isOpen, toggle = _a.toggle;
    var currentAutomationState = useAutomation().currentAutomationState;
    var _b = useReview(), games = _b.games, displayDate = _b.displayDate, rocRunnerStation = _b.rocRunnerStation, statsApiReviewStatus = _b.statsApiReviewStatus, rocRunnerReviewStatus = _b.rocRunnerReviewStatus;
    var statsApiIconColor = statsApiReviewStatus ? "red" : "grey";
    var rocRunnerIconColor = rocRunnerReviewStatus ? "red" : "grey";
    return (_jsx(ThemeProvider, __assign({ theme: { statsApiIconColor: statsApiIconColor, rocRunnerIconColor: rocRunnerIconColor } }, { children: _jsxs(SidebarContainer, __assign({ isOpen: isOpen }, { children: [_jsxs(Nav, { children: [_jsx(NavLogo, { children: "SETTINGS" }), _jsx(ScrollingBanner, __assign({ statsApiReviewStatus: statsApiReviewStatus, rocRunnerReviewStatus: rocRunnerReviewStatus }, { children: rocRunnerReviewStatus
                                ? rocRunnerStation
                                : "NO REVIEWS IN PROGRESS" })), _jsxs(IconsContainer, { children: [_jsx(Alert2, { children: _jsx(APPReplayIcon, { children: _jsx(FaRunning, {}) }) }), _jsx(Icon2, { children: _jsx(ColoredCircle, { enabled: currentAutomationState === "enable" }) }), _jsx(Icon, __assign({ onClick: toggle }, { children: _jsx(CloseIcon, {}) }))] })] }), _jsx(ReviewProvider, { children: _jsx(SettingsLayout, {}) })] })) })));
};
export default Sidebar;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
