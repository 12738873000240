var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import { ToggleButtonGroup } from "@mui/material";
import { useAutomation } from "../../contexts/AutomationDataContext";
var StyledContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  flex-direction: row;\n  align-items: center;\n  width: 350px;\n  box-sizing: border-box;\n  margin-bottom: 0px;\n  margin-top: -190px;\n  margin-left: 100px;\n"], ["\n  display: flex;\n  justify-content: center;\n  flex-direction: row;\n  align-items: center;\n  width: 350px;\n  box-sizing: border-box;\n  margin-bottom: 0px;\n  margin-top: -190px;\n  margin-left: 100px;\n"])));
var ButtonWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-top: 165px;\n  margin-left: -318px;\n  margin-right: 30px;\n  justify-content: center;\n  align-items: center;\n  width: 320px;\n  height: 100px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-top: 165px;\n  margin-left: -318px;\n  margin-right: 30px;\n  justify-content: center;\n  align-items: center;\n  width: 320px;\n  height: 100px;\n"])));
var StyledTable = styled.table(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  border-spacing: 0;\n  border-radius: 10px;\n  overflow: hidden;\n  background-color: #121212;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  color: #ffffff;\n"], ["\n  width: 100%;\n  border-spacing: 0;\n  border-radius: 10px;\n  overflow: hidden;\n  background-color: #121212;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  color: #ffffff;\n"])));
var StyledTableHead = styled.thead(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: #bf0c3d;\n  color: white;\n"], ["\n  background: #bf0c3d;\n  color: white;\n"])));
var StyledTableHeader = styled.th(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  padding: 12px;\n  font-size: 24px;\n  text-align: center;\n"], ["\n  padding: 12px;\n  font-size: 24px;\n  text-align: center;\n"])));
var StyledToggleButtonGroup = styled(ToggleButtonGroup)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var StyledToggleButton = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  cursor: pointer;\n  font-size: 1.2rem;\n  font-weight: bold;\n  text-transform: uppercase;\n  background-position: center;\n  padding: 13px 20px;\n  border-radius: 0;\n  color: ", ";\n  background-color: ", ";\n  &:hover {\n    background-color: ", ";\n  }\n\n  &:first-child {\n    border-top-left-radius: 10px;\n    border-bottom-left-radius: 10px;\n  }\n\n  &:last-child {\n    border-top-right-radius: 10px;\n    border-bottom-right-radius: 10px;\n  }\n"], ["\n  cursor: pointer;\n  font-size: 1.2rem;\n  font-weight: bold;\n  text-transform: uppercase;\n  background-position: center;\n  padding: 13px 20px;\n  border-radius: 0;\n  color: ", ";\n  background-color: ", ";\n  &:hover {\n    background-color: ", ";\n  }\n\n  &:first-child {\n    border-top-left-radius: 10px;\n    border-bottom-left-radius: 10px;\n  }\n\n  &:last-child {\n    border-top-right-radius: 10px;\n    border-bottom-right-radius: 10px;\n  }\n"])), function (props) { return (props.selected ? "#2196f3" : "#ffff"); }, function (props) { return (props.selected ? "#e3f2fd" : "#121212"); }, function (props) { return (props.selected ? "#bbdefb" : "#f8bbd0"); });
var ButtonLayout = function () {
    var _a = useAutomation(), currentAutomationState = _a.currentAutomationState, updateAutomation = _a.updateAutomation;
    var handleToggleChange = function (event, selectedValue) {
        updateAutomation(selectedValue);
    };
    return (_jsxs(StyledContainer, { children: [_jsx(StyledTable, { children: _jsx(StyledTableHead, { children: _jsx(StyledTableHeader, __assign({ colSpan: 5 }, { children: "Enable/Disable Automation" })) }) }), _jsx(ButtonWrapper, { children: _jsxs(StyledToggleButtonGroup, { children: [_jsx(StyledToggleButton, __assign({ selected: currentAutomationState === "enable", onClick: function (e) { return handleToggleChange(e, "enable"); } }, { children: "Enable" })), _jsx(StyledToggleButton, __assign({ selected: currentAutomationState === "disable", onClick: function (e) { return handleToggleChange(e, "disable"); } }, { children: "Disable" }))] }) })] }));
};
export default ButtonLayout;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
