var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useContext, useState, useEffect, } from "react";
var SERVICES_URL = process.env.SERVICES_URL;
var SseContext = createContext(undefined);
var SseProvider = function (_a) {
    var children = _a.children;
    var _b = useState("disable"), automationState = _b[0], setAutomationState = _b[1];
    var _c = useState([]), rocRunnerState = _c[0], setRocRunnerState = _c[1];
    var _d = useState([]), rocStationsState = _d[0], setRocStationsState = _d[1];
    var _e = useState({
        lastAction: "",
        RSN: "Slate",
        NTL: "Slate",
    }), lastActionState = _e[0], setLastActionState = _e[1];
    useEffect(function () {
        var automationSSE = new EventSource("".concat(SERVICES_URL, "/sse/automationState"));
        automationSSE.addEventListener("automationState", function (event) {
            setAutomationState(event.data);
        });
        var rocRunnerSSE = new EventSource("".concat(SERVICES_URL, "/sse/rocRunner"));
        rocRunnerSSE.addEventListener("rocRunner", function (event) {
            console.log(event.data);
            setRocRunnerState(JSON.parse(event.data));
        });
        rocRunnerSSE.addEventListener("rocStations", function (event) {
            console.log(JSON.parse(event.data));
            var stationList = JSON.parse(event.data);
            var stations = [];
            for (var _i = 0, stationList_1 = stationList; _i < stationList_1.length; _i++) {
                var s = stationList_1[_i];
                var list = [s.gamePk, s.event_label, s.station];
                stations.push(list);
                // console.log(list);
            }
            // setRocStationsState(JSON.parse(event.data));
            setRocStationsState(stations);
        });
        var lastActionSSE = new EventSource("".concat(SERVICES_URL, "/sse/lastAction"));
        lastActionSSE.addEventListener("lastAction", function (event) {
            console.log(event.data);
            setLastActionState(JSON.parse(event.data));
        });
        // const statsApiSSE = new EventSource('http://localhost:8080/sse/statsApi');
        // statsApiSSE.onmessage = (event) => {
        //   const data = JSON.parse(event.data);
        //   console.log(data)
        //   // reviewContext?.setStatsApiReviewStatus(data);
        // };
        return function () {
            automationSSE.close();
            rocRunnerSSE.close();
            lastActionSSE.close();
        };
    }, []);
    return (_jsx(SseContext.Provider, __assign({ value: {
            automationState: automationState,
            rocRunnerState: rocRunnerState,
            lastActionState: lastActionState,
            rocStationsState: rocStationsState,
        } }, { children: children })));
};
var useSse = function () {
    var contextValue = useContext(SseContext);
    if (contextValue === undefined) {
        throw new Error("useSSE must be used within a SSEProvider");
    }
    return contextValue;
};
export { SseContext, SseProvider, useSse };
