var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import ButtonLayout from "../components/ButtonLayout/ButtonLayout";
import GamesTable from "../components/GamesTable/GamesTable";
import Stack from "@mui/material/Stack";
import { useReview } from "../contexts/ReviewDataContext";
export default function Layout() {
    var _a = useReview(), games = _a.games, displayDate = _a.displayDate, rocRunnerStation = _a.rocRunnerStation;
    return (_jsx(_Fragment, { children: _jsxs(Stack, __assign({ direction: "row", spacing: 0 }, { children: [_jsx(GamesTable, { games: games, displayDate: displayDate }), _jsx(ButtonLayout, {})] })) }));
}
