var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
// import NavBar from "./modules/NavBar";
import Stack from "@mui/material/Stack";
import Layout from "./modules/Layout";
import { ReviewProvider } from "./contexts/ReviewDataContext";
import { AutomationProvider } from "./contexts/AutomationDataContext";
import "./App.css";
import Navbar from "./components/Navbar";
import SettingsDropdown from "./components/SettingsDropdown";
import { SseProvider } from "./contexts/SseContext";
var App = function () {
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var toggle = function () {
        setIsOpen(!isOpen);
    };
    return (_jsx("div", __assign({ className: "container-fluid" }, { children: _jsx(SseProvider, { children: _jsxs(AutomationProvider, { children: [_jsxs(ReviewProvider, { children: [_jsx(Navbar, { toggle: toggle }), _jsx(SettingsDropdown, { isOpen: isOpen, toggle: toggle })] }), _jsx(Stack, __assign({ spacing: 10 }, { children: _jsx(ReviewProvider, { children: _jsx(Layout, {}) }) }))] }) }) })));
};
export default App;
