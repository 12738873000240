var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { IoSettingsSharp } from "react-icons/io5";
import { BiSolidCircle } from "react-icons/bi";
import { FaRunning } from "react-icons/fa";
import "animate.css";
import TrackVisibility from "react-on-screen";
import { useAutomation } from "../../contexts/AutomationDataContext";
import { useReview } from "../../contexts/ReviewDataContext";
import styled, { ThemeProvider, keyframes, css } from "styled-components";
import { Nav, NavbarContainer, NavItem, NavLinks, NavLogo, NavMenu, IconsContainer, IconWrapper, IconWrapper2, Alerts, } from "./NavbarElements";
var flashAnimation = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0% { color: #bf0c3d; }\n  50% { color: grey; }\n  100% { color: #bf0c3d; }\n"], ["\n  0% { color: #bf0c3d; }\n  50% { color: grey; }\n  100% { color: #bf0c3d; }\n"])));
var flashStyles = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  animation: ", " 1s infinite;\n"], ["\n  animation: ", " 1s infinite;\n"])), flashAnimation);
var APPReplayIcon = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: -60px;\n  color: ", ";\n  ", ";\n"], ["\n  margin-right: -60px;\n  color: ", ";\n  ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.rocRunnerIconColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.rocRunnerReviewStatus && flashStyles;
});
var scrollAnimation = keyframes(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  0% {\n    transform: translateX(100%);\n  }\n  100% {\n    transform: translateX(-5%);\n  }\n"], ["\n  0% {\n    transform: translateX(100%);\n  }\n  100% {\n    transform: translateX(-5%);\n  }\n"])));
var ScrollingBanner = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  white-space: nowrap;\n  overflow: hidden;\n  animation: ", " 10s linear infinite;\n  color: ", ";\n  font-weight: bold;\n  margin-top: 35px;\n  margin-left:-350px;\n  margin-right: ", ";\n  font-size: ", ";\n\n    \n\n  @media screen and (max-width: 1380px) {\n    margin-right: -50px;\n  }\n"], ["\n  white-space: nowrap;\n  overflow: hidden;\n  animation: ", " 10s linear infinite;\n  color: ", ";\n  font-weight: bold;\n  margin-top: 35px;\n  margin-left:-350px;\n  margin-right: ", ";\n  font-size: ", ";\n\n    \n\n  @media screen and (max-width: 1380px) {\n    margin-right: -50px;\n  }\n"])), scrollAnimation, function (_a) {
    var statsApiReviewStatus = _a.statsApiReviewStatus, rocRunnerReviewStatus = _a.rocRunnerReviewStatus;
    return statsApiReviewStatus || rocRunnerReviewStatus ? "#bf0c3d" : "grey";
}, function (_a) {
    var statsApiReviewStatus = _a.statsApiReviewStatus, rocRunnerReviewStatus = _a.rocRunnerReviewStatus;
    return statsApiReviewStatus || rocRunnerReviewStatus ? "-100px" : "0px";
}, function (_a) {
    var statsApiReviewStatus = _a.statsApiReviewStatus, rocRunnerReviewStatus = _a.rocRunnerReviewStatus;
    return statsApiReviewStatus || rocRunnerReviewStatus ? "1.2rem" : "1rem";
});
var ColoredCircleWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), function (_a) {
    var enabled = _a.enabled;
    return (enabled ? "#09DC2A" : "white");
});
var ColoredCircle = function (_a) {
    var enabled = _a.enabled;
    return (_jsx(ColoredCircleWrapper, __assign({ enabled: enabled }, { children: _jsx(BiSolidCircle, {}) })));
};
var Navbar = function (_a) {
    var toggle = _a.toggle;
    var currentAutomationState = useAutomation().currentAutomationState;
    var _b = useReview(), games = _b.games, displayDate = _b.displayDate, rocRunnerStation = _b.rocRunnerStation, statsApiReviewStatus = _b.statsApiReviewStatus, rocRunnerReviewStatus = _b.rocRunnerReviewStatus;
    var statsApiIconColor = statsApiReviewStatus ? "#bf0c3d" : "grey";
    var rocRunnerIconColor = rocRunnerStation.length > 1 ? "#bf0c3d" : "grey";
    return (_jsx(ThemeProvider, __assign({ theme: { statsApiIconColor: statsApiIconColor, rocRunnerIconColor: rocRunnerIconColor } }, { children: _jsx(Nav, { children: _jsxs(NavbarContainer, { children: [_jsx(TrackVisibility, { children: function (_a) {
                            var isVisible = _a.isVisible;
                            return (_jsx("div", __assign({ className: "animate__animated animate__fadeIn animate__slower" }, { children: _jsx(NavLogo, { children: "ROCLIVE" }) })));
                        } }), _jsx(ScrollingBanner, __assign({ statsApiReviewStatus: statsApiReviewStatus, rocRunnerReviewStatus: rocRunnerStation.length > 1 }, { children: rocRunnerStation.length > 1
                            ? "REPLAY MODE AT: " + rocRunnerStation
                            : "NO REVIEWS IN PROGRESS" })), _jsx(TrackVisibility, { children: function (_a) {
                            var isVisible = _a.isVisible;
                            return (_jsx("div", __assign({ className: "animate__animated animate__fadeIn animate__slower" }, { children: _jsx(NavMenu, { children: _jsx(NavItem, { children: _jsxs(IconsContainer, { children: [_jsx(NavLinks, { children: _jsx(IconWrapper, { children: _jsx(IoSettingsSharp, { onClick: toggle }) }) }), _jsx(NavLinks, { children: _jsx(IconWrapper2, { children: _jsx(ColoredCircle, { enabled: currentAutomationState === "enable" }) }) }), _jsx(Alerts, { children: _jsx(APPReplayIcon, { children: _jsx(FaRunning, {}) }) })] }) }) }) })));
                        } })] }) }) })));
};
export default Navbar;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
