// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js!../node_modules/animate.css/animate.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App {\n  text-align: center;\n  background: linear-gradient(to right, #090d12, #032e6e); \n\n  \n}\n\n\n/* \nfont-face {\n  font-family: \"Monocode-Regular-V01.02b\";\n  src: local(\"Monocode-Regular-V01.02b\"),\n  url(\"./fonts/Monocode-Regular-V01.02b.ttf\") format(\"truetype\");\n  font-weight: normal;\n  } \n\n  * {\n    font-family: \"Monocode-Regular-V01.02b\";\n  } */\n\n  body{\n    \n    /* background: #051e41; */\n\n    /* background: -webkit-linear-gradient(to right, #000000, #ffffff);  Chrome 10-25, Safari 5.1-6 */\n    background: linear-gradient(to right, #090d12, #032e6e); \n\n    /* min-width: 950px; */\n    /* overflow-x: scroll; */\n    \n  }", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uDAAuD;;;AAGzD;;;AAGA;;;;;;;;;;KAUK;;EAMH;;IAEE,yBAAyB;;IAEzB,iGAAiG;IACjG,uDAAuD;;IAEvD,sBAAsB;IACtB,wBAAwB;;EAE1B","sourcesContent":[".App {\n  text-align: center;\n  background: linear-gradient(to right, #090d12, #032e6e); \n\n  \n}\n\n\n/* \nfont-face {\n  font-family: \"Monocode-Regular-V01.02b\";\n  src: local(\"Monocode-Regular-V01.02b\"),\n  url(\"./fonts/Monocode-Regular-V01.02b.ttf\") format(\"truetype\");\n  font-weight: normal;\n  } \n\n  * {\n    font-family: \"Monocode-Regular-V01.02b\";\n  } */\n\n\n\n  @import 'animate.css';\n\n  body{\n    \n    /* background: #051e41; */\n\n    /* background: -webkit-linear-gradient(to right, #000000, #ffffff);  Chrome 10-25, Safari 5.1-6 */\n    background: linear-gradient(to right, #090d12, #032e6e); \n\n    /* min-width: 950px; */\n    /* overflow-x: scroll; */\n    \n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
