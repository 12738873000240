var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useCallback } from "react";
import styled from "styled-components";
import CameraButton from "./CameraButton";
import { useReview } from "../../contexts/ReviewDataContext";
import { useAutomation } from "../../contexts/AutomationDataContext";
var StyledCameraButtonsContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-right: 8px;\n"], ["\n  margin-top: 20px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-right: 8px;\n"])));
var ButtonWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  margin-top: 15px;\n  margin-bottom: 8px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  margin-top: 15px;\n  margin-bottom: 8px;\n"])));
var StyledContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 50%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 6rem;\n  margin-bottom: 7.5rem;\n\n  @media screen and (max-width: 1280px) {\n    width: 60%;\n  }\n"], ["\n  width: 50%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 6rem;\n  margin-bottom: 7.5rem;\n\n  @media screen and (max-width: 1280px) {\n    width: 60%;\n  }\n"])));
var GridContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: 85px;\n  margin-top: 2rem;\n"], ["\n  margin-left: 85px;\n  margin-top: 2rem;\n"])));
var StyledToggleButtonGroup = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 10px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 10px;\n"])));
var StyledToggleButton = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  cursor: pointer;\n  font-size: 1rem;\n  font-weight: bold;\n  text-transform: uppercase;\n  background-position: center;\n  padding: 10px 15px;\n  border-radius: 0;\n  color: ", ";\n  background-color: ", ";\n  &:hover {\n    background-color: ", ";\n  }\n\n  &:first-child {\n    border-top-left-radius: 10px;\n    border-bottom-left-radius: 10px;\n  }\n\n  &:last-child {\n    border-top-right-radius: 10px;\n    border-bottom-right-radius: 10px;\n  }\n"], ["\n  cursor: pointer;\n  font-size: 1rem;\n  font-weight: bold;\n  text-transform: uppercase;\n  background-position: center;\n  padding: 10px 15px;\n  border-radius: 0;\n  color: ", ";\n  background-color: ", ";\n  &:hover {\n    background-color: ", ";\n  }\n\n  &:first-child {\n    border-top-left-radius: 10px;\n    border-bottom-left-radius: 10px;\n  }\n\n  &:last-child {\n    border-top-right-radius: 10px;\n    border-bottom-right-radius: 10px;\n  }\n"])), function (props) { return (props.selected ? "#2196f3" : "#ffff"); }, function (props) { return (props.selected ? "#e3f2fd" : "#121212"); }, function (props) { return (props.selected ? "#bbdefb" : "#f8bbd0"); });
var StyledBox = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 250px;\n  height: 200px;\n  margin-top: 30px;\n  background: #121212;\n  padding: 10px;\n  border-radius: 10px;\n"], ["\n  width: 250px;\n  height: 200px;\n  margin-top: 30px;\n  background: #121212;\n  padding: 10px;\n  border-radius: 10px;\n"])));
var TopLine = styled.p(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: 1.2rem;\n  line-height: 1;\n  font-weight: 700;\n  letter-spacing: 1.4px;\n  text-transform: uppercase;\n  margin-bottom: -15px;\n  // margin-top: 50px;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  margin-top: 20px;\n  color: grey;\n"], ["\n  font-size: 1.2rem;\n  line-height: 1;\n  font-weight: 700;\n  letter-spacing: 1.4px;\n  text-transform: uppercase;\n  margin-bottom: -15px;\n  // margin-top: 50px;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  margin-top: 20px;\n  color: grey;\n"])));
var Heading = styled.p(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-bottom: -10px;\n  margin-top: 21px;\n  text-transform: uppercase;\n  font-size: 1.1rem;\n  line-height: 1.1;\n  font-weight: 600;\n  color: #6ba1d4;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n"], ["\n  margin-bottom: -10px;\n  margin-top: 21px;\n  text-transform: uppercase;\n  font-size: 1.1rem;\n  line-height: 1.1;\n  font-weight: 600;\n  color: #6ba1d4;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n"])));
var ButtonLayout = function () {
    var _a = useState(""), truck = _a[0], setTruck = _a[1];
    var _b = useState("disable"), enable = _b[0], setEnable = _b[1];
    // const [lastAction, setLastAction] = useState<string>("");
    // const [rsnState, setRsnState] = useState<string>("Slate");
    // const [ntlState, setNtlState] = useState<string>("Slate");
    var _c = useState("reg"), gameType = _c[0], setGameType = _c[1];
    var _d = useState(null), alertMessage = _d[0], setAlertMessage = _d[1];
    var _e = useState(false), alert = _e[0], setAlert = _e[1];
    var _f = useReview(), ntlCam = _f.ntlCam, numOfReviews = _f.numOfReviews, truckList = _f.truckList, inReview = _f.inReview;
    var _g = useAutomation(), currentRSNState = _g.currentRSNState, currentNTLState = _g.currentNTLState, lastAction = _g.lastAction;
    // const handleToggleChange = (
    //   event: React.MouseEvent<HTMLElement>,
    //   selectedValue: string
    // ) => {
    //   switch (selectedValue) {
    //     case "NTL":
    //     case "RSN":
    //       setTruck(selectedValue);
    //       break;
    //     case "enable":
    //     case "disable":
    //       setEnable(selectedValue);
    //       break;
    //     case "reg":
    //     case "playoffs":
    //       setGameType(selectedValue);
    //       break;
    //   }
    // };
    var handleToggleChange = function (event, selectedValue) {
        switch (selectedValue) {
            case "NTL":
                setTruck(selectedValue);
                break;
            case "RSN":
                setTruck(selectedValue);
                break;
            case "enable":
                // setAutomation(selectedValue).then();
                setEnable(selectedValue);
                break;
            case "disable":
                // setAutomation(selectedValue).then();
                setEnable(selectedValue);
                break;
            case "reg":
                setGameType(selectedValue);
                break;
            case "playoffs":
                setGameType(selectedValue);
                break;
        }
    };
    // const getLastAction = useCallback((action: string) => {
    //   setLastAction(action);
    //   let camera = action.split(" ").slice(-1)[0];
    //   action.includes("NTL") ? setNtlState(camera) : setRsnState(camera);
    // }, []);
    var getAlert = useCallback(function (alert) {
        setAlertMessage(alert ? "Please select either 'NTL' or 'RSN' first." : null);
    }, []);
    // useEffect(() => {
    //   const broadcast: Broadcast = { NTL: "ME:1", RSN: "MME:1" };
    //   const displayAngleNames: any = {
    //     "IN:1": "CAM1",
    //     "IN:2": "CAM2",
    //     "IN:3": "CAM3",
    //     "IN:4": "Slate",
    //   };
    //   console.log(
    //     "in review status: ",
    //     inReview,
    //     "num of reviews: ",
    //     numOfReviews,
    //     "with trucks: ",
    //     truckList,
    //   );
    //   if (currentAutomationState === "enable") {
    //     let lastActionString = "";
    //     let ntlStation = "";
    //     let playoffOutput = "";
    //     ntlCam.forEach((cam) => {
    //       if (cam === "C2") {
    //         ntlStation = "IN:2";
    //         if (gameType === "playoffs") {
    //           playoffOutput = "RSN";
    //         }
    //       }
    //       if (cam === "C3") {
    //         ntlStation = "IN:3";
    //         if (gameType === "playoffs") {
    //           playoffOutput = "NTL";
    //         }
    //       }
    //     });
    //     const rsnAngle = inReview ? "IN:1" : "IN:4";
    //     const ntlAngle = inReview ? ntlStation : "IN:4";
    //
    //     if (gameType === "playoffs") {
    //       if (playoffOutput !== "") {
    //         lastActionString =
    //           "auto switched " +
    //           playoffOutput +
    //           " to " +
    //           displayAngleNames[ntlAngle];
    //         console.log(lastActionString);
    //         playoffOutput === "RSN"
    //           ? setRsnState(displayAngleNames[ntlAngle])
    //           : setNtlState(displayAngleNames[ntlAngle]);
    //       }
    //     } else {
    //       if (truckList.has("NTL")) {
    //         if (ntlStation !== "") {
    //           setCameraAngle(
    //             ntlAngle,
    //             `${broadcast["NTL"]}:PST`,
    //             broadcast["NTL"],
    //           ).then(() => {
    //             console.log("switched NTL");
    //           });
    //           lastActionString =
    //             "auto switched NTL to " + displayAngleNames[ntlAngle];
    //           setNtlState(displayAngleNames[ntlAngle]);
    //         }
    //       }
    //       if (truckList.has("RSN")) {
    //         setCameraAngle(
    //           rsnAngle,
    //           `${broadcast["RSN"]}:PST`,
    //           broadcast["RSN"],
    //         ).then(() => {
    //           console.log("switched RSN");
    //         });
    //         lastActionString === ""
    //           ? (lastActionString =
    //               "auto switched RSN to " + displayAngleNames[rsnAngle])
    //           : (lastActionString =
    //               lastActionString +
    //               " and " +
    //               "RSN to " +
    //               displayAngleNames[rsnAngle]);
    //         setRsnState(displayAngleNames[rsnAngle]);
    //       }
    //       // truckList.forEach((truck) => {
    //       //   if (truck === "NTL" && ntlStation !== "") {
    //       //     setCameraAngle(
    //       //       ntlAngle,
    //       //       `${broadcast["NTL"]}:PST`,
    //       //       broadcast["NTL"],
    //       //     ).then(() => {
    //       //       lastActionString =
    //       //         "auto switched " + truck + " to " + displayAngleNames[ntlAngle];
    //       //       setNtlState(displayAngleNames[ntlAngle]);
    //       //     });
    //       //   }
    //       //   if (truck === "RSN") {
    //       //     setCameraAngle(
    //       //       rsnAngle,
    //       //       `${broadcast["RSN"]}:PST`,
    //       //       broadcast["RSN"],
    //       //     ).then();
    //       //     lastActionString === ""
    //       //       ? (lastActionString =
    //       //           "auto switched " +
    //       //           truck +
    //       //           " to " +
    //       //           displayAngleNames[rsnAngle])
    //       //       : (lastActionString =
    //       //           lastActionString +
    //       //           "and " +
    //       //           truck +
    //       //           " to " +
    //       //           displayAngleNames[rsnAngle]);
    //       //     setRsnState(displayAngleNames[rsnAngle]);
    //       //   }
    //       // });
    //     }
    //     setLastAction(lastActionString);
    //   }
    // }, [inReview, numOfReviews]);
    return (_jsx(StyledContainer, { children: _jsx(GridContainer, { children: _jsxs(StyledContainer, { children: [_jsx(ButtonWrapper, { children: _jsxs(StyledToggleButtonGroup, { children: [_jsx(StyledToggleButton, __assign({ selected: gameType === "reg", onClick: function (e) { return handleToggleChange(e, "reg"); } }, { children: "Regular" })), _jsx(StyledToggleButton, __assign({ selected: gameType === "playoffs", onClick: function (e) { return handleToggleChange(e, "playoffs"); } }, { children: "Playoffs" }))] }) }), _jsx(ButtonWrapper, { children: _jsxs(StyledToggleButtonGroup, { children: [_jsx(StyledToggleButton, __assign({ selected: truck === "NTL", onClick: function (e) { return handleToggleChange(e, "NTL"); } }, { children: "NTL" })), _jsx(StyledToggleButton, __assign({ selected: truck === "RSN", onClick: function (e) { return handleToggleChange(e, "RSN"); } }, { children: "RSN" }))] }) }), _jsxs(StyledCameraButtonsContainer, { children: [_jsx(CameraButton, { camera: "CAM1", truck: truck, alert: getAlert }), _jsx(CameraButton, { camera: "CAM2", truck: truck, alert: getAlert }), _jsx(CameraButton, { camera: "CAM3", truck: truck, alert: getAlert }), _jsx(CameraButton, { camera: "SLATE", truck: truck, alert: getAlert })] }), _jsxs(StyledBox, { children: [_jsx(TopLine, { children: "Last Action:" }), _jsx(Heading, { children: lastAction }), _jsx(TopLine, { children: "NTL Output" }), _jsx(Heading, { children: currentNTLState }), _jsx(TopLine, { children: "RSN Output" }), _jsx(Heading, { children: currentRSNState })] })] }) }) }));
};
export default ButtonLayout;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
