var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useContext, useEffect, useState, } from "react";
import { getSchedule } from "../api/StatsApi";
import { useSse } from "./SseContext";
var ReviewContext = createContext(undefined);
var ReviewProvider = function (_a) {
    var children = _a.children;
    var _b = useState(false), inReview = _b[0], setInReview = _b[1];
    var _c = useState(""), displayDate = _c[0], setDisplayDate = _c[1];
    var _d = useState([]), games = _d[0], setGames = _d[1];
    var _e = useState([]), amqInfo = _e[0], setAmqinfo = _e[1];
    var _f = useState(new Set()), truckList = _f[0], setTruckList = _f[1];
    var _g = useState(new Set()), ntlCam = _g[0], setNtlCam = _g[1];
    var _h = useState(""), rocRunnerStation = _h[0], setRocrunnerReviewStation = _h[1];
    var _j = useState(0), numOfReviews = _j[0], setNumOfReviews = _j[1];
    var _k = useState(false), statsApiReviewStatus = _k[0], setStatsApiReviewStatus = _k[1];
    var _l = useState(false), rocRunnerReviewStatus = _l[0], setRocRunnerReviewStatus = _l[1];
    var _m = useSse(), rocRunnerState = _m.rocRunnerState, rocStationsState = _m.rocStationsState;
    var fetchStatsApiData = function () {
        //offset to account for games that go past midnight
        var utcOffset = -7;
        var utcMilliseconds = new Date().getTime() + utcOffset * 60 * 60 * 1000; // Convert offset to milliseconds
        var offsetDate = new Date(utcMilliseconds);
        var month = offsetDate.getMonth() + 1; // Months start at 0 so add 1
        var day = offsetDate.getDate();
        var year = offsetDate.getFullYear();
        var todaysDate = "".concat(year, "-").concat(month
            .toString()
            .padStart(2, "0"), "-").concat(day.toString().padStart(2, "0"));
        // const displayDate: string = `${month.toString().padStart(2, "0")}.${day
        //   .toString()
        //   .padStart(2, "0")}`;
        // setDisplayDate(displayDate);
        // shows date as ex: "12.15"
        var displayDateOptions = {
            weekday: "long",
            month: "long",
            day: "numeric",
        };
        var displayDate = offsetDate.toLocaleDateString("en-US", displayDateOptions);
        setDisplayDate(displayDate);
        // lines 68-75 display date as "Friday, December 15"
        getSchedule(todaysDate)
            .then(function (response) {
            var newSchedule = [];
            var finalGames = [];
            var activeReviews = 0;
            if (response.dates[0]) {
                var allGames = response.dates[0].games;
                var suspGamePk_1 = "";
                allGames.forEach(function (g) {
                    var ntlTrucks = [
                        "ESPN",
                        "TBS",
                        "TruTV",
                        "Apple TV+",
                        // "MLBN",
                        "FOX",
                        "ABC",
                        "ESPN2",
                        // "FS1",
                        "FOX / FS1",
                        "Roku",
                    ];
                    var ntl = 0;
                    var rsn = 0;
                    //for playoffs, route stations 3/4 to output 1 (NTL) and stations 6/7 to output 2 (RSN)
                    // if (
                    //   g.seriesDescription === "Wild Card Game" ||
                    //   g.seriesDescription === "Division Series" ||
                    //   g.seriesDescription === "League Championship Series" ||
                    //   g.seriesDescription === "World Series"
                    // ) {
                    // }
                    if (!g.broadcasts) {
                        rsn++;
                        // ntl++;
                    }
                    else {
                        g.broadcasts.forEach(function (bcast) {
                            if (bcast.type === "TV") {
                                if (bcast.callSign === "MLBN" && bcast.sourceComment) {
                                    if (bcast.sourceComment === "Showcase") {
                                        ntl++;
                                    }
                                }
                                else if (ntlTrucks.includes(bcast.callSign)) {
                                    ntl++;
                                }
                                else if (!bcast.isNational) {
                                    rsn++;
                                }
                            }
                        });
                    }
                    var trucks = "";
                    if (ntl > 0 && rsn > 0) {
                        trucks = "NTL, RSN";
                    }
                    else if (ntl === 0 && rsn > 0) {
                        trucks = "RSN";
                    }
                    else if (rsn === 0 && ntl > 0) {
                        trucks = "NTL";
                    }
                    var gameString = g.teams.away.team.abbreviation +
                        " @ " +
                        g.teams.home.team.abbreviation;
                    //store susp gamePk to reference later
                    if (g.officialDate !== todaysDate) {
                        suspGamePk_1 = g.gamePk;
                    }
                    //DH should use first gamePK since replay app only uses first game's gamePK
                    var gamePk = "";
                    if (g.doubleHeader !== "N" && g.gameNumber !== 1) {
                        var dhGamePk_1 = "";
                        newSchedule.forEach(function (g) {
                            if (g.gameString === gameString) {
                                dhGamePk_1 = g.gamePk;
                            }
                        });
                        finalGames.forEach(function (g) {
                            if (g.gameString === gameString) {
                                dhGamePk_1 = g.gamePk;
                            }
                        });
                        gamePk = dhGamePk_1;
                    }
                    else {
                        newSchedule.forEach(function (g) {
                            if (g.gameString === gameString) {
                                gamePk = suspGamePk_1;
                            }
                        });
                        finalGames.forEach(function (g) {
                            if (g.gameString === gameString) {
                                gamePk = suspGamePk_1;
                            }
                        });
                        if (gamePk === "") {
                            gamePk = g.gamePk;
                        }
                    }
                    var rocStation = "";
                    rocStationsState.forEach(function (rocGame) {
                        if (rocGame[0] === g.gamePk) {
                            rocStation = rocGame[2].toString();
                        }
                    });
                    var gameDetail = {
                        gamePk: g.gamePk,
                        replayGamePk: gamePk,
                        gameString: gameString,
                        gameTime: new Date(g.gameDate + "Z").toLocaleTimeString("en-US", {
                            timeZone: "America/New_York",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                        }),
                        gameState: g.status.detailedState,
                        //station: "",
                        trucks: trucks,
                        camstate: true,
                        enableState: true,
                        rocStation: rocStation,
                    };
                    if (g.status.detailedState === "Manager challenge" ||
                        g.status.detailedState === "Umpire review" ||
                        g.status.abstractGameCode === "M" ||
                        g.status.abstractGameCode === "N") {
                        activeReviews++;
                    }
                    if (g.status.detailedState === "Final" ||
                        g.status.detailedState === "Completed Early" ||
                        g.status.detailedState === "Game Over") {
                        finalGames.push(gameDetail);
                    }
                    else {
                        newSchedule.push(gameDetail);
                    }
                });
                setGames(__spreadArray(__spreadArray([], newSchedule, true), finalGames, true));
                activeReviews > 0
                    ? setStatsApiReviewStatus(true)
                    : setStatsApiReviewStatus(false);
            }
            else {
                var noGames = {
                    gamePk: "",
                    replayGamePk: "",
                    gameString: "No games scheduled",
                    gameTime: "",
                    gameState: "",
                    trucks: "",
                    camstate: false,
                    enableState: false,
                    rocStation: "",
                };
                setGames([noGames]);
            }
        })
            .catch(function (error) {
            console.error("API call error:", error);
        });
    };
    // const fetchRocAmqData = () => {
    //   getAmqReviewState()
    //     .then((amqReviewState: boolean) => {
    //       setRocRunnerReviewStatus(amqReviewState);
    //     })
    //     .catch((error) => {
    //       console.error("API call error:", error);
    //     });
    //
    //   getAmqReviewStationInfo()
    //     .then((info) => {
    //       setAmqinfo(info);
    //     })
    //     .catch((error) => {
    //       console.error("API call error:", error);
    //     });
    // };
    useEffect(function () {
        // Fetch initial data when the component mounts
        fetchStatsApiData();
        // fetchRocAmqData();
        var interval = setInterval(function () {
            fetchStatsApiData();
            // fetchRocAmqData();
        }, 60000);
        return function () {
            clearInterval(interval); // Clear the interval when the component unmounts
        };
    }, [rocStationsState]);
    //SSE for rocrunner updates
    useEffect(function () {
        setAmqinfo(rocRunnerState);
        // rocRunnerState.length > 0 ? setInReview(true) : setInReview(false);
        console.log(rocRunnerState);
    }, [rocRunnerState]);
    useEffect(function () {
        if (amqInfo.length > 0 && amqInfo[0].length > 0) {
            // console.log(amqInfo.length);
            var reviewStationString_1 = "";
            var truckList_1 = [];
            var ntlStationList_1 = [];
            var truckSet_1 = new Set();
            // let ntlCam = "";
            setTruckList(new Set());
            amqInfo.forEach(function (game) {
                var gameString = "";
                games.forEach(function (g) {
                    if (g.gamePk == game[1]) {
                        gameString = g.gameString;
                        // if (
                        //   g.gameState === "In Progress" ||
                        //   g.gameState === "Manager challenge" ||
                        //   g.gameState === "Umpire review"
                        // ) {
                        truckList_1.push("RSN");
                        truckSet_1.add("RSN");
                        if (g.trucks.includes("NTL")) {
                            if (game[0] === "3" || game[0] === "4") {
                                // ntlCam = "C3";
                                ntlStationList_1.push("C3");
                            }
                            else if (game[0] === "6" || game[0] === "7") {
                                // ntlCam = "C2";
                                ntlStationList_1.push("C2");
                            }
                            //testing with wrong station
                            else {
                                ntlStationList_1.push("C2");
                            }
                            truckList_1.push("NTL");
                            truckSet_1.add("NTL");
                        }
                        // } else {
                        //   gameString = "- APP IN TESTING";
                        // }
                    }
                });
                if (reviewStationString_1 == "" && gameString !== "") {
                    reviewStationString_1 = "Station " + game[0] + " Game " + gameString;
                }
                else if (gameString !== "") {
                    reviewStationString_1 =
                        reviewStationString_1 +
                            ", Station " +
                            game[0] +
                            " Game " +
                            gameString;
                }
            });
            // console.log(truckList);
            console.log(reviewStationString_1);
            console.log(ntlStationList_1);
            // console.log(truckSet);
            setNtlCam(new Set(ntlStationList_1));
            // setNtlCam(ntlCam);
            setTruckList(new Set(truckList_1));
            setRocrunnerReviewStation(reviewStationString_1);
            setNumOfReviews(amqInfo.length);
            setInReview(true);
        }
        else {
            setRocrunnerReviewStation("");
            setNumOfReviews(0);
            setInReview(false);
        }
        // if (rocRunnerStation !== "") {
        //   setInReview(true);
        //   setNumOfReviews(amqInfo.length);
        // } else if (rocRunnerStation === "") {
        //   setInReview(false);
        //   setNumOfReviews(amqInfo.length);
        // }
    }, [statsApiReviewStatus, rocRunnerReviewStatus, amqInfo]);
    return (_jsx(ReviewContext.Provider, __assign({ value: {
            games: games,
            displayDate: displayDate,
            ntlCam: ntlCam,
            numOfReviews: numOfReviews,
            truckList: truckList,
            rocRunnerStation: rocRunnerStation,
            inReview: inReview,
            statsApiReviewStatus: statsApiReviewStatus,
            rocRunnerReviewStatus: rocRunnerReviewStatus,
        } }, { children: children })));
};
var useReview = function () {
    var reviewContext = useContext(ReviewContext);
    if (reviewContext === undefined) {
        throw new Error("useReview must be used within a ReviewProvider");
    }
    return reviewContext;
};
export { ReviewContext, ReviewProvider, useReview };
