var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { setCameraAngle } from "../../api/CarboniteApi";
import styled from "styled-components";
var StyledButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: #bf0c3d;\n  color: #fff;\n  padding: 10px;\n  font-size: 1rem;\n  margin: 6px;\n  cursor: pointer;\n  border: none;\n  border-radius: 10px;\n  outline: none;\n  font-style: bold;\n  transition: 0.3s ease-in-out;\n  padding: 10px 15px;\n  text-transform: uppercase;\n  font-weight: bold;\n  background-position: center;\n  transition: background 0.8s;\n\n  &:hover {\n    // background: linear-gradient(90.21deg, #63a4dc  -5.91%, #ffff 111.58%);\n    // color: black;\n\n    background: linear-gradient(to right, #1d232c, #093577);\n    color: white;\n    transform: scale(1.1);\n  }\n"], ["\n  background: #bf0c3d;\n  color: #fff;\n  padding: 10px;\n  font-size: 1rem;\n  margin: 6px;\n  cursor: pointer;\n  border: none;\n  border-radius: 10px;\n  outline: none;\n  font-style: bold;\n  transition: 0.3s ease-in-out;\n  padding: 10px 15px;\n  text-transform: uppercase;\n  font-weight: bold;\n  background-position: center;\n  transition: background 0.8s;\n\n  &:hover {\n    // background: linear-gradient(90.21deg, #63a4dc  -5.91%, #ffff 111.58%);\n    // color: black;\n\n    background: linear-gradient(to right, #1d232c, #093577);\n    color: white;\n    transform: scale(1.1);\n  }\n"])));
var CameraButton = function (_a) {
    var camera = _a.camera, truck = _a.truck, alert = _a.alert, onClick = _a.onClick;
    var _b = useState(false), clicked = _b[0], setClicked = _b[1];
    // const handleButtonClick = () => {
    //   if (!enable) {
    //     // Display an alert or handle the case when the app is not enabled
    //     window.alert('Please enable the app first before clicking camera buttons.');
    //     console.log('App not enabled');
    //     return;
    //   }
    //   if (onClick) {
    //     onClick();
    //   }
    // };
    useEffect(function () {
        var broadcast = { NTL: "ME:1", RSN: "MME:1" };
        if (truck.length > 1 && camera.length > 1 && clicked) {
            switch (camera) {
                case "CAM1":
                    setCameraAngle("IN:1", "".concat(broadcast[truck], ":PST"), broadcast[truck]).then();
                    break;
                case "CAM2":
                    setCameraAngle("IN:2", "".concat(broadcast[truck], ":PST"), broadcast[truck]).then();
                    break;
                case "CAM3":
                    setCameraAngle("IN:3", "".concat(broadcast[truck], ":PST"), broadcast[truck]).then();
                    break;
                case "SLATE":
                    setCameraAngle("IN:4", "".concat(broadcast[truck], ":PST"), broadcast[truck]).then();
                    break;
            }
            setClicked(false);
        }
    }, [clicked]);
    return (_jsx("div", { children: _jsx(StyledButton, __assign({ onClick: function () {
                setClicked(true);
                // handleButtonClick();
            } }, { children: camera })) }));
};
export default CameraButton;
var templateObject_1;
